
.profile-card {
  width: 100%;
  height: 100vh;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  background-color: #fff;
}

.profile-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e5e5e5;
  border-radius: 10px;
  padding: 20px;
}

.profile-avatar {
  width: 100px;
  height: 200px;
  border-radius: 50%;
  background-color: #e5e5e5;
  
}

.edit-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.profile-info {
  text-align: center;
  margin-top: 10px;
}

.profile-info h3 {
  margin: 0;
  font-size: 20px;
  font-weight: bold;
}

.username {
  margin: 5px 0;
  font-size: 14px;
  color: #888;
}

.joined-date,
.friends-count {
  font-size: 12px;
  color: #888;
}

.flags img {
  margin: 5px;
  width: 20px;
  height: 20px;
}

.statistics {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 20px;
}

.stat {
  width: 30%;
  margin-bottom: 10px;
  text-align: center;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px;
  box-sizing: border-box;
}

.stat-icon {
  font-size: 24px;
  margin-bottom: 5px;
}

.stat-number {
  font-size: 18px;
  font-weight: bold;
}

.stat-label {
  font-size: 12px;
  color: #888;
}
