@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  margin: 0;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  font-family: Arial, sans-serif;
  background-color: #f7f3f0;
}
.unauthenticated {
  width: 100vw;
  height: auto;
}

.main {
  height: 85vh !important;
  overflow: scroll;
}

.phone-container {
  width: 375px;
  height: 667px;
  border: 16px solid black;
  border-radius: 36px;
  padding: 20px;
  background: white;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.phone-screen {
  width: 100%;
  height: 100%;
  border: 1px solid #ccc;
  border-radius: 20px;
  overflow: hidden;
  background: #f8f9fa;
}

.phone-notch {
  width: 60px;
  height: 30px;
  background: black;
  border-radius: 0 0 20px 20px;
  position: absolute;
  top: -20px;
  left: 50%;
  transform: translateX(-50%);
}

/* Media queries for smaller screens */
@media (max-width: 767px) {
  body,
  html {
    display: block;
    background-color: #ffffff;
  }

  .phone-container {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
    padding: 0;
    box-shadow: none;
  }

  .phone-screen {
    border-radius: 0;
    background: #ffffff;
  }

  .phone-notch {
    display: none;
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  max-height: 90%;
  overflow-y: auto;
  position: relative;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 24px;
}
