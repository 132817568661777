.card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px 0;
    font-family: Arial, sans-serif;
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .card-header h3 {
    margin: 0;
    font-size: 1em;
  }
  
  .emoji {
    font-size: small;
  }
  
  .card p {
    color: #555;
    font-size: 0.9em;
    margin: 10px 0;
  }
  
  .icons {
    margin-top: 10px;
  }
  
  .icon {
    font-size: small;
    color: grey;
  }


  