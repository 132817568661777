.journal-card {
  height: 60vh;
}

.add-to-journal {
  margin: 10px;
}

.content p {
  font-size: medium;
  font-weight: lighter;
}

.content small {
  font-size: small;
  font-weight: lighter;
  text-decoration: underline;
}
