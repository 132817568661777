.header {
  display: flex;
  height: 5vh;
  border-radius: 5% 5% 0 0;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 5px;
  /* background: linear-gradient(45deg, #656171, #c7bfe1); */
  background-color: #000;

  gap: 5px;
}
.create-journal {
  margin: 10px;
}

.clock {
  font-size: 1 rem;
  color: #fff;
}

.journal-textarea {
  width: 100%;
  height: 30vh;
  border: none;
  padding: 10px;
  border-radius: 15px;
  font-size: 1.2em;
  margin-bottom: 10px;

  box-shadow: 4px 3px 10px rgba(0, 0, 0, 0.4);
}

.journal-textarea::placeholder {
  color: #000;
}
.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.add-button {
  padding: 10px 20px;
  border: none;
  border-radius: 20px;
  background-color: #656171;
  color: #fff;
  font-size: 1em;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  margin-bottom: 20px;
}

.add-button:hover {
  box-shadow: 4px 4px 8px rgb(0, 0, 0, 0.4);
  transform: scale(1.05);
}

.chat {
  overflow: scroll;
  height: 40vh;
}

.chat-entry.Assistant {
  color: #000;
  border: 2px solid #656171;
  max-width: 90%;
  padding: 15px;
  border-radius: 10px 10px 0 0;
  font-size: 0.7rem;
  border-bottom: none;
  font-weight: bold;
}

.chat-entry.You {
  width: 100%;
  background-color: #c7bfe1;
  color: #000;
  padding: 20px;
  border: 1px solid #e0e0e0;
  margin-bottom: 20px;
  max-width: 90%;
  border-radius: 0 0 10px 10px;
  font-size: 0.8rem;
  font-weight: bold;
}

.reflection-entry {
  padding: 10px;
  background-color: #e5e1f1;
  border-radius: 10px;

  margin: 20px 0;
}

.reflection-box {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

.keywords {
  background-color: #c7bfe1;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  font-weight: bold;
  color: #000;
  margin-bottom: 20px;
}

h4 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  font-weight: bold;
}

.mood {
  font-size: 20px;
  color: #4caf50;
}

.reflection-box h4 {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #000;
  margin-bottom: 10px;
}

.reflection-box h4 svg {
  margin-right: 10px;
  color: #867da5;
}

.reflection-box h4.positive-icon svg {
  color: #28a745;
}
.reflection-box h4.negative-icon svg {
  color: #dc3545;
}
.reflection-box h4.mood-icon svg {
  color: #ffa500;
}
.reflection-box h4.summary-icon svg {
  color: #8a2be2;
}
.reflection-box h4.analysis-icon svg {
  color: #17a2b8;
}
.reflection-box h4.feedback-icon svg {
  color: #007bff;
}
.reflection-box h4.action-icon svg {
  color: #ff5733;
}
