/* FooterNav.css */
.footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  background-color: #ffffff;
  width: 100%;
  height: 70px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-shadow: 0 -4px 15px rgba(0, 0, 0, 0.1);
  border-radius: 20px 20px 0 0;
  z-index: 1;
}

.footer-item {
  flex: 1;
  text-align: center;
  position: relative;
  transition: transform 0.3s ease;
}

.footer-icon {
  width: 24px;
  height: 24px;
  opacity: 0.8;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.footer-text {
  display: block;
  font-size: 12px;
  margin-top: 5px;
  color: #333;
}

.footer-item.active .footer-icon {
  opacity: 1;
  transform: scale(1.1);
}

.center-icon-wrapper {
  position: relative;
  top: -30px;
  width: 70px;
  height: 70px;
  background-color: #ffffff;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.center-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-icon-img {
  width: 30px;
  height: 30px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer {
    height: 10vh;
    padding: 8px;
  }

  .footer-item .footer-icon {
    width: 20px;
    height: 20px;
  }

  .footer-item.active .footer-icon {
    transform: scale(1.1);
  }

  .highlight-circle {
    width: 40px;
    height: 40px;
    top: -25px;
  }

  .footer-text {
    font-size: 10px;
  }
}
