.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.selectBox {
  margin-bottom: 16px;
  width: 80vw;
}

.select {
  margin-top: 20px;
}

.divBox {
  width: 300px;
  height: 300px;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.button {
  margin-top: 16px;
}

.paragraph {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 16px;
}

/* ChatWindow.css */
.chat-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.chat-header {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.chat-title {
  margin: 0;
  font-size: 24px;
}

.chat-description {
  margin: 5px 0 0;
  font-size: 16px;
  color: #666;
}

.chat-box {
  padding: 10px;
  height: 300px;
  overflow-y: auto;
  border-bottom: 1px solid #ddd;
}

.message {
  margin: 5px 0;
  padding: 10px;
  border-radius: 5px;
  max-width: 70%;
  word-wrap: break-word;
}

.message.user {
  background-color: #e1ffc7;
  margin-left: auto;
  text-align: right;
}

.message.bot {
  background-color: #f1f0f0;
}

.chat-footer {
  display: flex;
  flex-direction: column;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.chat-footer textarea {
  flex: 1;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  resize: none;
  overflow-y: auto;
}

.chat-footer button {
  align-self: flex-end;
  padding: 8px 12px;
  border: none;
  background-color: #007bff;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

.chat-footer button:hover {
  background-color: #0056b3;
}

.button-container button {
  padding: 8px 12px;
  border: none;
  margin: 5px;
  color: white;
  border-radius: 3px;
  cursor: pointer;
}

.button-container button:nth-child(1) {
  background-color: #dc3545; /* Red background for "End Session" */
}

.button-container button:nth-child(1):hover {
  background-color: #c82333; /* Darker red for hover */
}

.button-container button:nth-child(2) {
  background-color: #007bff; /* Blue background for "Send" */
}

.button-container button:nth-child(2):hover {
  background-color: #0056b3; /* Darker blue for hover */
}
