@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f6f3ff;
}

.login-title {
  margin-bottom: 20px;
  font-size: 64px;
  color: #000000;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
}

.login-button {
  padding: 10px 20px;
  font-size: 18px;
  color: #fff;
  background-color: #656171;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #3d3a44;
}

.splash-logo {
  width: 80vw;
}

.login-title {
  font-size: 30px;
}

@media (min-width: 768px) {
  .login-button {
    font-size: 16px;
    padding: 8px 16px;
  }

  .splash-logo {
    width: 20vw
  }
}

