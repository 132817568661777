.header {
  height: 26vh;
  background-color: #4F3423;
  color: white;
  padding: 20px;
  border-radius: 0px 0px 30px 30px;
}

.date {
  color: #E3DDD6;
  font-size: small;
}

.hello {
  margin-top: 30px;
  font-size: x-large;
}

.badges {
  margin-top: 20px;
  display: flex;
  width: 100%;
}

.badges div {
  flex: 1;
  box-sizing: border-box;
  padding: 10px;
  text-align: center;
}

.container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  padding: 0 5px;
  box-sizing: border-box;
}

.circle {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-basis: 14%; /* Ensures that there are seven circles in the container */
  max-width: 60px; /* Ensures that the circles don't grow too big */
  aspect-ratio: 1; /* Maintains the circle shape */
  border: 2px solid #000;
  border-radius: 50%;
  box-sizing: border-box;
  padding: 10px;
  margin: 2px;
  text-align: center;
}

.marker {
  background-color: greenyellow;
}

.day-tracker {
  font-size: 14px;
  font-weight: bold;
}

.date-tracker {
  font-size: 16px;
  font-weight: bold;
}


.journal-header {
  padding: 10px;
}


.date-input {
  background-color: transparent; 
  border: none; 
  font-family: inherit; 
  font-size: inherit; 
  color: inherit; 
  outline: none; 
  cursor: pointer; 
  padding-right: 20px; 
  appearance: none; 
  -webkit-appearance: none; 
  position: relative;
}

.date-input::-webkit-calendar-picker-indicator {
  position: absolute;
  right: 0;
  width: 16px;
  height: 16px; 
  cursor: pointer;
}

.date-input::-webkit-inner-spin-button,
.date-input::-webkit-clear-button {
  display: none; 
}


.floating-button {
  position: fixed;
  bottom: 50px;
  right: 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  z-index: 1000; /* Ensures it stays on top of other elements */
  transition: background-color 0.3s;
}

.floating-button:hover {
  background-color: #0056b3;
}