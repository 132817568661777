.dashboard-header {
  width: 100vw;
  height: 25vh;
  background-color: #c7bfe1;
  padding: 20px;
  border-radius: 0px 0px 30px 30px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.4);
}

.month-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}

.month-selector button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 0 10px;
}

.month-selector span {
  font-size: 20px;
  font-weight: bold;
  color: black;
}

.weekdays {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
}

.weekday {
  text-align: center;
  color: black;
  font-weight: bold;
}

.dates {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  justify-items: center;
}

.date {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  border-radius: 50%;
  color: #656171;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.date:hover {
  background-color: black;
  color: white;
}

.date.selected {
  background-color: #656171;
  color: white;
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.date-picker-container {
  background-color: #656171;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.7);
}

.react-datepicker__navigation {
  align-items: center;
  background: none;
  display: flex;
  justify-content: center;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 2px;
  padding: 0;
  border-radius: 50%;
  z-index: 1;
  height: 28px;
  width: 28px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
  border-color: #000;
  border-style: solid;
  border-width: 3px 3px 0 0;
  content: "";
  display: block;
  height: 9px;
  position: absolute;
  top: 6px;
  width: 9px;
}

.selected-date {
  margin-top: 50px;
  font-size: 18px; 
  color: #656171;
  font-weight: bold; 
}

.react-datepicker__header {
  background-color: #c7bfe1;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 50%;
  background-color: #c7bfe1;
  color: #000;
}


.date-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.day {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}

.day .day-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.day .date {
  font-size: 1em;
}

.day .date.selected {
  border: 2px solid grey;
  border-radius: 50%;
  padding: 10px;
  box-sizing: border-box;
}
